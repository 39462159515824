import "./src/styles/global.css"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeuFuUhAAAAACH2z4NYEE2A09i7Mr4QA20koFOf">
      {element}
    </GoogleReCaptchaProvider>
  )
}
